'use client'

import { useSearchParams } from 'next/navigation'
import { signIn } from 'next-auth/react'

function SignInToMember() {
  const searchParams = useSearchParams()
  signIn('okta', { callbackUrl: `/member?${searchParams?.toString()}` })
  return null
}

export default SignInToMember
